<template>
  <section>
    <div class="content-header">
      <h2>About oppression</h2>
    </div>
    <div class="content-wrapper">
      <p>Oppression is defined in the Merriam-Webster dictionary as: “Unjust or cruel exercise of authority or power especially by the imposition of burdens; the condition of being weighed down; an act of pressing down; a sense of heaviness or obstruction in the body or mind”.</p>
      <p>Another way to define oppression is as the social act of placing severe restrictions on an individual, group or institution.</p>
      <p>These definitions demonstrate the intensity of oppression, and help us understand how difficult it is to address or eradicate. </p>
      <p>So, what is the reason for oppression in the first place? </p>
      <p>Typically, a government or political organization that is in power places restrictions (formally or covertly) on groups so that the distribution of resources is unfairly allocated—and this means power stays in the hands of those who already have it. For example, oil extraction causes disproportionate harms to First Nations communities, but government officials with ties to the oil industry do not want to pass legislation that would threaten their profits. First Nations communities are oppressed by the people and systems that allow this to happen. </p>
      <p class="lm">Learn more</p>
      <p>In this short video, <a href="https://www.youtube.com/watch?v=ZOOUmOzUeTY" target="_blank">“Privilege vs. Oppression”</a>, Dr. Cheryl Ingram helps us understand the relationship between privilege and oppression.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
